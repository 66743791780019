/** @jsx jsx */
import { Component } from "react"
import { graphql, Link } from "gatsby"
import { jsx } from "theme-ui"
import { Heading, Text, Box } from "@theme-ui/components"
import { FixedLayout } from "../components"

export default class Partners extends Component {
  render() {
    const {
      data: {
        craft: { entry },
      },
    } = this.props

    return (
      <FixedLayout
        seoTitle={entry.title}
        image={entry.featuredImage[0].filename}
        imageTitle={`“Black and silver vintage camera” by Alexander Andrews (via unsplash.com)`}
        imageBackgroundColor="#333"
      >
        <Box>
          <Link
            to={`/the-office-guys/`}
            sx={{ variant: "text.minicaps", py: 3, display: "inline-block" }}
          >
            / Office Guys
          </Link>
          <Heading variant="display">{entry.title}</Heading>
          <Text
            variant="bodytext"
            className="reading"
            dangerouslySetInnerHTML={{ __html: entry.body.content }}
          />
        </Box>
      </FixedLayout>
    )
  }
}

export const partnerQuery = graphql`
  query QueryPartners($id: [Int]!) {
    craft {
      entry(id: $id) {
        ... on Craft_Partners {
          title
          body {
            content
          }
          headshot {
            filename
          }
          featuredImage {
            filename
          }
        }
      }
    }
  }
`
